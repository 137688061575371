import {
  Box,
  Container,
  HStack,
  Link as ChakraLink,
  useColorModeValue,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

export const Navigation = () => {
  const bgColor = useColorModeValue('white', 'gray.800')
  const borderColor = useColorModeValue('gray.200', 'gray.700')

  return (
    <Box bg={bgColor} borderBottom="1px" borderColor={borderColor}>
      <Container maxW="container.xl">
        <HStack spacing={8} py={4}>
          <ChakraLink as={RouterLink} to="/" fontWeight="bold">
            Home
          </ChakraLink>
          <ChakraLink as={RouterLink} to="/contact">
            Contact
          </ChakraLink>
          <ChakraLink as={RouterLink} to="/privacy">
            Privacy Policy
          </ChakraLink>
        </HStack>
      </Container>
    </Box>
  )
}
