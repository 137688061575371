import { Box, useColorModeValue } from '@chakra-ui/react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Navigation } from './components/Navigation'
import { Home } from './components/Home'
import { Contact } from './components/Contact'
import { PrivacyPolicy } from './components/PrivacyPolicy'

function App() {
  const bgColor = useColorModeValue('gray.50', 'gray.900')

  return (
    <Router>
      <Box minH="100vh" bg={bgColor}>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </Box>
    </Router>
  )
}

export default App
