import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Link,
  UnorderedList,
  ListItem,
  useColorModeValue,
} from '@chakra-ui/react'
import { FaEnvelope, FaBug } from 'react-icons/fa'
import { SEO } from './SEO'

export const Contact = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900')
  const cardBg = useColorModeValue('white', 'gray.800')

  return (
    <>
      <SEO 
        title="Contact Us"
        description="Get in touch with the Contribute Open Source team. Report issues, provide feedback, or ask questions about finding beginner-friendly open source projects."
      />
      <Box minH="100vh" bg={bgColor}>
        <Container maxW="container.md" py={8}>
          <VStack spacing={8} align="stretch">
            <Box textAlign="center">
              <Heading as="h1" size="xl" mb={4}>
                Contact Us
              </Heading>
              <Text fontSize="lg" color="gray.600">
                Get in Touch
              </Text>
            </Box>

            <Box bg={cardBg} p={6} borderRadius="lg" shadow="sm">
              <Text mb={4}>
                We'd love to hear from you! If you have any questions, feedback, or concerns, 
                please don't hesitate to reach out.
              </Text>

              <VStack align="start" spacing={6}>
                <Box>
                  <Link
                    href="mailto:ksanjeev284@gmail.com"
                    display="inline-flex"
                    alignItems="center"
                    color="blue.500"
                    fontSize="lg"
                    mb={2}
                  >
                    <FaEnvelope style={{ marginRight: '8px' }} />
                    ksanjeev284@gmail.com
                  </Link>
                </Box>

                <Box>
                  <Heading as="h3" size="md" mb={4} display="flex" alignItems="center">
                    <FaBug style={{ marginRight: '8px' }} />
                    Report Technical Issues
                  </Heading>
                  <Text mb={3}>
                    When reporting technical issues, please include:
                  </Text>
                  <UnorderedList spacing={2} pl={4}>
                    <ListItem>Browser type and version</ListItem>
                    <ListItem>Device type</ListItem>
                    <ListItem>Description of the issue</ListItem>
                    <ListItem>Screenshots (if possible)</ListItem>
                  </UnorderedList>
                </Box>

                <Box>
                  <Text fontStyle="italic" color="gray.600">
                    Note: For urgent matters, please include "URGENT" in your email subject line.
                  </Text>
                </Box>
              </VStack>
            </Box>
          </VStack>
        </Container>
      </Box>
    </>
  )
}
