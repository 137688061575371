import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  UnorderedList,
  ListItem,
  useColorModeValue,
} from '@chakra-ui/react'
import { SEO } from './SEO'

export const PrivacyPolicy = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900')
  const cardBg = useColorModeValue('white', 'gray.800')

  return (
    <>
      <SEO 
        title="Privacy Policy"
        description="Learn about how Contribute Open Source handles your data, our use of cookies, and our commitment to protecting your privacy."
      />
      <Box minH="100vh" bg={bgColor}>
        <Container maxW="container.md" py={8}>
          <VStack spacing={8} align="stretch">
            <Box textAlign="center">
              <Heading as="h1" size="xl" mb={4}>
                Privacy Policy
              </Heading>
              <Text fontSize="lg" color="gray.600">
                Last updated: December 14, 2024
              </Text>
            </Box>

            <Box bg={cardBg} p={6} borderRadius="lg" shadow="sm">
              <VStack spacing={6} align="stretch">
                <Box>
                  <Heading as="h2" size="md" mb={3}>
                    1. Introduction
                  </Heading>
                  <Text>
                    Welcome to Good First Issues. We respect your privacy and are committed to protecting your personal data. 
                    This privacy policy explains how we handle information when you use our website.
                  </Text>
                </Box>

                <Box>
                  <Heading as="h2" size="md" mb={3}>
                    2. Information We Collect
                  </Heading>
                  <Text mb={3}>We collect and process the following types of information:</Text>
                  <UnorderedList spacing={2} pl={4}>
                    <ListItem>
                      <strong>Usage Data:</strong> Information about how you use our website, including:
                      <UnorderedList>
                        <ListItem>Browser type and version</ListItem>
                        <ListItem>Time and date of visits</ListItem>
                        <ListItem>Pages viewed</ListItem>
                        <ListItem>Time spent on pages</ListItem>
                      </UnorderedList>
                    </ListItem>
                    <ListItem>
                      <strong>GitHub Data:</strong> Public information from GitHub's API when displaying issues
                    </ListItem>
                  </UnorderedList>
                </Box>

                <Box>
                  <Heading as="h2" size="md" mb={3}>
                    3. How We Use Your Information
                  </Heading>
                  <Text mb={3}>We use your information to:</Text>
                  <UnorderedList spacing={2} pl={4}>
                    <ListItem>Provide and maintain our service</ListItem>
                    <ListItem>Improve user experience</ListItem>
                    <ListItem>Analyze usage patterns</ListItem>
                    <ListItem>Debug technical issues</ListItem>
                  </UnorderedList>
                </Box>

                <Box>
                  <Heading as="h2" size="md" mb={3}>
                    4. Google AdSense
                  </Heading>
                  <Text mb={3}>
                    We use Google AdSense to display advertisements. Google AdSense may use cookies and web beacons to:
                  </Text>
                  <UnorderedList spacing={2} pl={4}>
                    <ListItem>Serve personalized ads</ListItem>
                    <ListItem>Track ad impressions and interactions</ListItem>
                    <ListItem>Understand user preferences</ListItem>
                  </UnorderedList>
                  <Text mt={3}>
                    For more information about Google AdSense, please visit Google's privacy policy.
                  </Text>
                </Box>

                <Box>
                  <Heading as="h2" size="md" mb={3}>
                    5. Local Storage
                  </Heading>
                  <Text>
                    We use browser's local storage to cache GitHub issues data temporarily to improve performance 
                    and reduce API calls. This data is stored locally on your device and expires after 15 minutes.
                  </Text>
                </Box>

                <Box>
                  <Heading as="h2" size="md" mb={3}>
                    6. Third-Party Services
                  </Heading>
                  <Text mb={3}>We use the following third-party services:</Text>
                  <UnorderedList spacing={2} pl={4}>
                    <ListItem>GitHub API for fetching issues</ListItem>
                    <ListItem>Google AdSense for displaying advertisements</ListItem>
                  </UnorderedList>
                </Box>

                <Box>
                  <Heading as="h2" size="md" mb={3}>
                    7. Data Security
                  </Heading>
                  <Text>
                    We implement appropriate security measures to protect your information. However, 
                    no method of transmission over the internet is 100% secure, and we cannot guarantee 
                    absolute security.
                  </Text>
                </Box>

                <Box>
                  <Heading as="h2" size="md" mb={3}>
                    8. Changes to This Policy
                  </Heading>
                  <Text>
                    We may update this privacy policy from time to time. We will notify you of any changes 
                    by posting the new privacy policy on this page and updating the "Last updated" date.
                  </Text>
                </Box>

                <Box>
                  <Heading as="h2" size="md" mb={3}>
                    9. Contact Us
                  </Heading>
                  <Text>
                    If you have any questions about this privacy policy, please contact us at ksanjeev284@gmail.com
                  </Text>
                </Box>
              </VStack>
            </Box>
          </VStack>
        </Container>
      </Box>
    </>
  )
}
