import { useState, useEffect, useCallback } from 'react'
import {
  Box,
  SimpleGrid,
  Text,
  Link,
  Badge,
  VStack,
  Spinner,
  Alert,
  HStack,
  useToast,
  Flex
} from '@chakra-ui/react'
import { FaGithub, FaExternalLinkAlt, FaExclamationCircle, FaCode } from 'react-icons/fa'
import axios from 'axios'

const ITEMS_PER_PAGE = 20;
const CACHE_EXPIRY = 1000 * 60 * 60 * 12; // 12 hours cache
const LANGUAGE_CACHE_EXPIRY = 1000 * 60 * 60 * 24 * 7; // 7 days for languages
const MAX_CONCURRENT_REQUESTS = 2;

// Rate limit tracking
let rateLimitRemaining = 5000;
let rateLimitReset = 0;
const MIN_RATE_LIMIT_THRESHOLD = 100;

const checkRateLimit = (headers) => {
  rateLimitRemaining = parseInt(headers['x-ratelimit-remaining'] || rateLimitRemaining);
  rateLimitReset = parseInt(headers['x-ratelimit-reset'] || rateLimitReset);
  
  if (rateLimitRemaining < MIN_RATE_LIMIT_THRESHOLD) {
    const resetTime = new Date(rateLimitReset * 1000);
    const minutesUntilReset = Math.ceil((resetTime - new Date()) / (1000 * 60));
    throw new Error(`API rate limit is low. Resets in ${minutesUntilReset} minutes at ${resetTime.toLocaleTimeString()}`);
  }
};

export const IssuesList = ({ filters }) => {
  const [issues, setIssues] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [sortField, setSortField] = useState('created')
  const [sortOrder, setSortOrder] = useState('desc')
  const [repoLanguages, setRepoLanguages] = useState({})
  const [preloadedPages, setPreloadedPages] = useState({})
  const [isPreloading, setIsPreloading] = useState(false)
  const toast = useToast()

  const getCacheKey = (query, page, sort, order) => {
    return `issues-cache-${query}-${page}-${sort}-${order}`;
  };

  const getFromCache = (cacheKey, expiryTime = CACHE_EXPIRY) => {
    const cached = localStorage.getItem(cacheKey);
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < expiryTime) {
        return data;
      }
      localStorage.removeItem(cacheKey);
    }
    return null;
  };

  const setToCache = (cacheKey, data) => {
    try {
      localStorage.setItem(
        cacheKey,
        JSON.stringify({
          data,
          timestamp: Date.now(),
        })
      );
    } catch (error) {
      // If localStorage is full, clear old caches
      clearOldCaches();
    }
  };

  const clearOldCaches = () => {
    const keys = Object.keys(localStorage);
    const oldestFirst = keys
      .filter(key => key.startsWith('issues-cache-') || key.startsWith('repo-languages-'))
      .map(key => ({
        key,
        timestamp: JSON.parse(localStorage.getItem(key)).timestamp
      }))
      .sort((a, b) => a.timestamp - b.timestamp);

    // Remove oldest 20% of caches
    const toRemove = Math.ceil(oldestFirst.length * 0.2);
    oldestFirst.slice(0, toRemove).forEach(item => {
      localStorage.removeItem(item.key);
    });
  };

  const batchRequests = async (requests, batchSize = MAX_CONCURRENT_REQUESTS) => {
    const results = [];
    for (let i = 0; i < requests.length; i += batchSize) {
      const batch = requests.slice(i, i + batchSize);
      const batchResults = await Promise.all(batch);
      results.push(...batchResults);
      // Add a small delay between batches to avoid rate limiting
      if (i + batchSize < requests.length) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
    return results;
  };

  const preloadNextPages = useCallback(async (currentPage) => {
    if (isPreloading || rateLimitRemaining < MIN_RATE_LIMIT_THRESHOLD * 2) return;
    setIsPreloading(true);

    try {
      // Only preload one page at a time to conserve rate limits
      const nextPage = currentPage + 1;
      if (nextPage <= Math.ceil(totalCount / ITEMS_PER_PAGE) && !preloadedPages[nextPage]) {
        const cacheKey = getCacheKey(
          `is:issue is:open label:"good first issue"${
            filters.language ? ` language:${filters.language}` : ''
          }${filters.label ? ` label:"${filters.label}"` : ''}`,
          nextPage,
          sortField,
          sortOrder
        );

        // Check cache first
        const cachedData = getFromCache(cacheKey);
        if (cachedData) {
          setPreloadedPages(prev => ({
            ...prev,
            [nextPage]: cachedData
          }));
          return;
        }

        const query = `is:issue is:open label:"good first issue"${
          filters.language ? ` language:${filters.language}` : ''
        }${filters.label ? ` label:"${filters.label}"` : ''}`;

        const response = await axios.post('/.netlify/functions/github-api', {
          path: 'search/issues',
          query,
          page: nextPage.toString(),
          sort: sortField,
          order: sortOrder,
        });

        if (response.data && response.data.items) {
          setPreloadedPages(prev => ({
            ...prev,
            [nextPage]: response.data.items
          }));
          // Cache the preloaded data
          setToCache(cacheKey, response.data.items);
        }
      }
    } catch (error) {
      console.error('Error preloading pages:', error);
      // Don't show toast for preload errors
    } finally {
      setIsPreloading(false);
    }
  }, [filters, sortField, sortOrder, preloadedPages, isPreloading, totalCount]);

  const fetchIssues = useCallback(async (pageNum = 1, background = false) => {
    if (!background) {
      setLoading(true);
      setError(null);
    }

    try {
      const query = `is:issue is:open label:"good first issue"${
        filters.language ? ` language:${filters.language}` : ''
      }${filters.label ? ` label:"${filters.label}"` : ''}`;

      // Try to get data from local storage first
      const cacheKey = `issues:${query}:${pageNum}:${sortField}:${sortOrder}`;
      const cachedData = localStorage.getItem(cacheKey);
      
      if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData);
        const isExpired = Date.now() - timestamp > CACHE_EXPIRY;
        
        // Show cached data immediately
        if (!background) {
          setIssues(data.items);
          setTotalCount(data.total_count);
          setLoading(false);
        }
        
        // If cache is expired or this is a background refresh, fetch new data
        if (isExpired || background) {
          fetchFreshData();
        }
        
        return;
      }

      // If no cache, fetch fresh data
      fetchFreshData();

      async function fetchFreshData() {
        const response = await axios.post('/.netlify/functions/github-api', {
          path: 'search/issues',
          query,
          page: pageNum.toString(),
          sort: sortField,
          order: sortOrder,
        });

        const newData = response.data;
        
        // Cache the new data
        localStorage.setItem(cacheKey, JSON.stringify({
          data: newData,
          timestamp: Date.now()
        }));

        if (!background) {
          setIssues(newData.items);
          setTotalCount(newData.total_count);
          setLoading(false);
        }
      }

    } catch (error) {
      console.error('Error fetching issues:', error);
      if (!background) {
        setError(error.response?.data?.error || error.message);
        setLoading(false);
      }
    }
  }, [filters, sortField, sortOrder]);

  // Initial load and background refresh
  useEffect(() => {
    fetchIssues(page, false); // Show immediate results
    
    // Start background refresh for next few pages
    const preloadPages = async () => {
      for (let i = 1; i <= 3; i++) {
        await fetchIssues(i, true);
      }
    };
    preloadPages();
  }, [fetchIssues, page]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && (!totalCount || newPage <= Math.ceil(totalCount / ITEMS_PER_PAGE))) {
      setPage(newPage);
      window.scrollTo(0, 0);
    }
  };

  if (loading && page === 1) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert status="error">
        <Box display="flex" alignItems="center" gap={2}>
          <FaExclamationCircle />
          Error loading issues: {error}
        </Box>
      </Alert>
    );
  }

  const totalPages = Math.max(1, Math.ceil(totalCount / ITEMS_PER_PAGE));
  const showingFrom = issues.length === 0 ? 0 : ((page - 1) * ITEMS_PER_PAGE) + 1;
  const showingTo = Math.min(page * ITEMS_PER_PAGE, showingFrom + issues.length - 1);

  return (
    <Box>
      <Flex justify="space-between" mb={6} align="center">
        <Text>
          {totalCount > 0 ? (
            `Showing ${showingFrom} - ${showingTo} of ${totalCount} issues`
          ) : (
            'No issues found'
          )}
        </Text>
        <HStack spacing={4}>
          <select
            value={sortField}
            onChange={(e) => setSortField(e.target.value)}
            style={{ padding: '8px', borderRadius: '4px', marginRight: '8px' }}
          >
            <option value="created">Created Date</option>
            <option value="updated">Updated Date</option>
            <option value="comments">Comments</option>
          </select>
          <select
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
            style={{ padding: '8px', borderRadius: '4px' }}
          >
            <option value="desc">Descending</option>
            <option value="asc">Ascending</option>
          </select>
        </HStack>
      </Flex>

      {loading ? (
        <Box textAlign="center" py={10}>
          <Spinner size="xl" />
        </Box>
      ) : issues.length > 0 ? (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {issues.map((issue) => (
            <Box
              key={issue.id}
              p={5}
              shadow="md"
              borderWidth="1px"
              borderRadius="lg"
              _hover={{ shadow: 'lg' }}
              position="relative"
            >
              <VStack align="stretch" spacing={3}>
                <Link
                  href={issue.html_url}
                  isExternal
                  fontWeight="bold"
                  display="flex"
                  alignItems="center"
                  gap={2}
                >
                  {issue.title}
                  <FaExternalLinkAlt size="0.8em" />
                </Link>
                <Link
                  href={issue.repository_url.replace('api.github.com/repos', 'github.com')}
                  isExternal
                  color="gray.600"
                  fontSize="sm"
                  display="flex"
                  alignItems="center"
                  gap={2}
                >
                  <FaGithub />
                  {issue.repository_url.split('/').slice(-2).join('/')}
                </Link>

                <Text fontSize="sm" noOfLines={3}>
                  {issue.body}
                </Text>

                <Box>
                  {issue.labels.map((label) => (
                    <Badge
                      key={label.id}
                      mr={2}
                      mb={2}
                      colorScheme="green"
                      variant="subtle"
                    >
                      {label.name}
                    </Badge>
                  ))}
                </Box>

                {repoLanguages[issue.repository_url] && (
                  <Box>
                    <HStack spacing={2} align="flex-start" color="gray.600" fontSize="sm" mb={1}>
                      <FaCode style={{ marginTop: '4px' }} />
                      <Text>Languages:</Text>
                    </HStack>
                    <Flex wrap="wrap" gap={2}>
                      {repoLanguages[issue.repository_url].map((lang) => (
                        <Badge
                          key={lang}
                          colorScheme="blue"
                          variant="subtle"
                          fontSize="xs"
                        >
                          {lang}
                        </Badge>
                      ))}
                    </Flex>
                  </Box>
                )}

                <Text fontSize="xs" color="gray.500">
                  Created: {new Date(issue.created_at).toLocaleDateString()}
                  {issue.comments > 0 && ` • ${issue.comments} comments`}
                </Text>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>
      ) : (
        <Box textAlign="center" py={10}>
          <Text>No issues found matching your criteria</Text>
        </Box>
      )}

      {totalCount > 0 && (
        <Flex justify="center" mt={6} gap={2}>
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
            style={{ 
              padding: '8px 16px', 
              borderRadius: '4px', 
              opacity: page === 1 ? 0.5 : 1,
              backgroundColor: '#EDF2F7',
              cursor: page === 1 ? 'not-allowed' : 'pointer'
            }}
          >
            Previous
          </button>
          {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
            const pageNum = page <= 3 ? i + 1 : page - 2 + i;
            if (pageNum <= totalPages) {
              return (
                <button
                  key={pageNum}
                  onClick={() => handlePageChange(pageNum)}
                  style={{
                    padding: '8px 16px',
                    borderRadius: '4px',
                    backgroundColor: pageNum === page ? '#4299E1' : '#EDF2F7',
                    color: pageNum === page ? 'white' : 'inherit',
                    cursor: 'pointer'
                  }}
                >
                  {pageNum}
                </button>
              );
            }
            return null;
          })}
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= totalPages}
            style={{ 
              padding: '8px 16px', 
              borderRadius: '4px', 
              opacity: page >= totalPages ? 0.5 : 1,
              backgroundColor: '#EDF2F7',
              cursor: page >= totalPages ? 'not-allowed' : 'pointer'
            }}
          >
            Next
          </button>
        </Flex>
      )}
    </Box>
  );
}
