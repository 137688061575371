import { Box, Container, Heading, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { IssuesList } from './IssuesList'
import { Filters } from './Filters'
import { SEO } from './SEO'

export const Home = () => {
  const [filters, setFilters] = useState({
    language: '',
    label: 'good first issue',
    sort: 'created'
  })

  return (
    <>
      <SEO 
        title="Find Beginner-Friendly Open Source Issues"
        description="Discover and contribute to open source projects. Filter by programming language, search by labels, and start your open source journey today."
      />
      <Container maxW="container.xl" py={8}>
        <Box>
          <Heading textAlign="center" size="2xl" mb={2}>
            Good First Issues
          </Heading>
          <Text textAlign="center" color="gray.600" mb={8}>
            Find beginner-friendly open source issues to contribute to
          </Text>
          <Filters onFilterChange={setFilters} />
          <Box mt={8}>
            <IssuesList filters={filters} />
          </Box>
        </Box>
      </Container>
    </>
  )
}
