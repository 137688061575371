import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const SEO = ({ title, description }) => {
  const location = useLocation();
  const baseUrl = 'https://contributeopensource.com';
  const currentUrl = `${baseUrl}${location.pathname}`;

  useEffect(() => {
    // Update meta tags
    document.title = title ? `${title} | Contribute Open Source` : 'Contribute Open Source - Find Beginner-Friendly Issues';
    
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description || 'Find beginner-friendly open source issues to contribute to. Filter by programming language, search by labels, and start your open source journey today.');
    }

    // Update canonical URL
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.setAttribute('href', currentUrl);
    }

    // Update Open Graph tags
    const ogTitle = document.querySelector('meta[property="og:title"]');
    const ogDescription = document.querySelector('meta[property="og:description"]');
    const ogUrl = document.querySelector('meta[property="og:url"]');

    if (ogTitle) {
      ogTitle.setAttribute('content', title ? `${title} | Contribute Open Source` : 'Contribute Open Source - Find Beginner-Friendly Issues');
    }
    if (ogDescription) {
      ogDescription.setAttribute('content', description || 'Find beginner-friendly open source issues to contribute to. Filter by programming language, search by labels, and start your open source journey today.');
    }
    if (ogUrl) {
      ogUrl.setAttribute('content', currentUrl);
    }

    // Update Twitter tags
    const twitterTitle = document.querySelector('meta[property="twitter:title"]');
    const twitterDescription = document.querySelector('meta[property="twitter:description"]');
    const twitterUrl = document.querySelector('meta[property="twitter:url"]');

    if (twitterTitle) {
      twitterTitle.setAttribute('content', title ? `${title} | Contribute Open Source` : 'Contribute Open Source - Find Beginner-Friendly Issues');
    }
    if (twitterDescription) {
      twitterDescription.setAttribute('content', description || 'Find beginner-friendly open source issues to contribute to. Filter by programming language, search by labels, and start your open source journey today.');
    }
    if (twitterUrl) {
      twitterUrl.setAttribute('content', currentUrl);
    }
  }, [title, description, currentUrl]);

  return null;
};
