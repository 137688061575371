import { useState, useEffect } from 'react'
import {
  Box,
  Select,
  Stack,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue
} from '@chakra-ui/react'
import { FaSearch } from 'react-icons/fa'

// Popular programming languages
const POPULAR_LANGUAGES = [
  'JavaScript',
  'Python',
  'Java',
  'TypeScript',
  'C++',
  'C#',
  'PHP',
  'Ruby',
  'Go',
  'Rust',
  'Swift',
  'Kotlin'
].sort()

export const Filters = ({ onFilterChange }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const bgColor = useColorModeValue('white', 'gray.800')

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      onFilterChange({
        language: selectedLanguage,
        label: searchTerm ? searchTerm : 'good first issue'
      })
    }, 500)

    return () => clearTimeout(debounceTimer)
  }, [selectedLanguage, searchTerm, onFilterChange])

  return (
    <Box
      p={4}
      bg={bgColor}
      borderRadius="lg"
      shadow="sm"
      mb={6}
    >
      <Stack spacing={4}>
        <Box>
          <Text mb={2} fontWeight="medium">Programming Language</Text>
          <Select
            placeholder="All Languages"
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
          >
            {POPULAR_LANGUAGES.map((lang) => (
              <option key={lang} value={lang.toLowerCase()}>
                {lang}
              </option>
            ))}
          </Select>
        </Box>

        <Box>
          <Text mb={2} fontWeight="medium">Search Labels</Text>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <FaSearch color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search labels (e.g., documentation, bug)"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </Box>
      </Stack>
    </Box>
  )
}
